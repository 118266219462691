import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Voucher from "../components/Voucher"

export const query = graphql`
  {
    allContentfulBon {
      nodes {
        naglowek1
        naglowek2
        lista1
        lista2
        opis {
          opis
        }
      }
    }
  }
`

function voucherPage({ data }) {
  const {
    allContentfulBon: { nodes: voucherData },
  } = data

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>fizjowsulkowski | Bon Podarunkowy</title>
        <meta name="theme-color" content={"white"} />
      </Helmet>
      <Voucher voucherData={voucherData[0]} />
    </>
  )
}

export default voucherPage
