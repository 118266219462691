import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"

import voucherSrc from "../../assets/images/bon.jpg"

const StyledRow = styled(Row)`
  margin-top: 10rem;

  p {
    font-size: 18px;
    color: rgb(122, 122, 122);
  }

  @media (min-width: 576px) {
    border-left: 5px solid #e5007d;
  }
`

const Li = styled.li`
  color: rgb(122, 122, 122);
`

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

function Voucher({ voucherData }) {
  const {
    naglowek1: title1,
    naglowek2: title2,
    lista1: list1,
    lista2: list2,
    opis: { opis: description },
  } = voucherData

  return (
    <Container className="mt-6">
      {/* <Row>
        <h1 className="page-header ml-auto mr-auto">Bon Podarunkowy</h1>
      </Row> */}
      <StyledRow>
        <Col>
          <h1>{title1}</h1>
          <p className="mt-3">{description}</p>
        </Col>
      </StyledRow>
      <Row className="mt-5 mb-5">
        <Col lg={6}>
          <StyledImage src={voucherSrc} alt="voucher" />
        </Col>
        <Col lg={6} className="mb-4 d-flex align-items-center">
          <div>
            <h3 className="mt-4">{title2}</h3>
            <ul>
              <Li>{list1}</Li>
              <Li>{list2}</Li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Voucher
